import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import ImagenPlatoRevolver5080 from '../components/imageComponents/imagenPlatoRevolver5080'

const IndexPage = () => (
  <EnLayout>
    <SEO title='Stirring plate 5080' />
    <Header siteTitle='OR-PRINTER Stirring plate 5080' />

    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
          <ImagenPlatoRevolver5080 />
        </div>
        <div className='col-12 col-md-6 conmargen'>
          <h3 className={Estilos.username}>Technical specifications:</h3>
          <ul className='aplicaciones'>
            <li> Semi-automatic machine driven by electric motor. </li>
            <li> Power supply: 230 V ~. </li>
            <li> Maximum electrical power: 750 W. </li>
            <li> Maximum Pressure: 1300 kgs. </li>
            <li> Maximum piece height: 65 mm. </li>
            <li> Maximum printing area: 140 x 80 mm. </li>
            <li> Working systems with stirring plate or pedal. </li>
            <li> Work rate of up to 1300 impressions / hour. </li>
            <li>Digital temperature regulator P.I.D. until 300&deg; C. </li>
            <li> Menu control screen with 20 memories </li>
            <li>
              Timer controlled electric motor film advance
              electronic.
             </li>
            <li> Self-tilting head, adjustable in height and inclination. </li>
            <li> Progressive, backward and totalizer digital counter. </li>
            <li> Lighting of the work area by means of LEDs. </li>
            <li> Weight: 82 Kg. </li>
            <li> Dimensions: Height: 81 cms. Width: 46 cms. Depth: 86 cms. </li>
          </ul>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
